import {
  formatDate,
  formatTime
} from '@smarttransit/common'

import {
  extractBaseNameFromFilePath
} from '@smarttransit/common-client'

import { addAlert } from '../../../../../utilities/helpers'

import _isEqual from 'lodash.isequal'
import _cloneDeep from 'lodash.clonedeep'

import {
  createBusStopAudio, deleteBusStopAudio,
  getAllBusStopAudioByBusStopId,
  getBusStopAudioSecureUrl,
  updateBusStop,
  updateBusStopAudio
} from '../../../../../services/bus-stops-service'

export default {
  name: 'bus-stops-audio-modal',
  props: {
    openModal: Boolean,
    isEditable: Boolean,
    locales: {
      type: Array,
      required: true
    },
    onModalClose: Function,
    selectedBusStop: Object
  },
  data () {
    return {
      currentBusStop: null,
      busStopAudioFiles: [],
      currentBusStopAudio: null,
      modalBusStopUpdate: false,
      apiInProgress: false,
      busStopAudioApiInProgress: false,
      busStopAudioDocument: { documentsToUpload: [], uploadedDocuments: [] }
    }
  },
  mounted () {
    this.modalBusStopUpdate = this.$props.openModal
  },
  watch: {
    openModal (newVal) {
      this.modalBusStopUpdate = newVal

      if (newVal) {
        this.loadBusStopForm(this.$props.selectedBusStop)
        this.loadBusStopAudioRecords(this.$props.selectedBusStop.id)
      }
    },
    busStopAudioDocument (documents) {
      if (this.currentBusStopAudio) {
        this.currentBusStopAudio.documentFile = null
      }

      if (documents?.documentsToUpload) {
        documents.documentsToUpload.forEach((newFile) => {
          if (!newFile.error) {
            this.currentBusStopAudio = {
              ...this.currentBusStopAudio,
              documentFile: newFile.file,
              documentFileUrl: URL.createObjectURL(newFile.file)
            }
          }
        })
      }
    }
  },
  methods: {
    // marshalBusStop (busStop, busStopAudioFiles) {
    //   busStop.dateCreatedLabel = busStop.dateCreated && `${formatDate(busStop.dateCreated)}, ${formatTime(busStop.dateCreated)}`
    //   busStop.dateUpdatedLabel = busStop.dateUpdated && `${formatDate(busStop.dateUpdated)}, ${formatTime(busStop.dateUpdated)}`
    //   busStop.dateSyncedLabel = busStop.dateSynced && `${formatDate(busStop.dateSynced)}, ${formatTime(busStop.dateSynced)}`
    //
    //   if (busStopAudioFiles && busStopAudioFiles.length) {
    //     busStop.audioLanguageLabels = busStopAudioFiles
    //       .filter((o) => (o.busStopId === busStop.id))
    //       .map((o) => (this.locales.find((a) => (a.value === o.locale))?.text))
    //   }
    //
    //   return busStop
    // },
    isUpdateDirty () {
      return !_isEqual(this.currentBusStop, this.currentBusStopCloned)
    },
    loadBusStopForm (busStop) {
      this.currentBusStop = _cloneDeep(busStop)
      this.currentBusStopCloned = busStop
    },
    closeBusStopForm () {
      this.modalBusStopUpdate = false

      if (this.$props.onModalClose) {
        this.$props.onModalClose()
      }
    },
    async loadBusStopAudioRecords (busStopId) {
      try {
        this.busStopAudioApiInProgress = true
        const busStopAudioFiles = await getAllBusStopAudioByBusStopId(busStopId)

        if (busStopAudioFiles && busStopAudioFiles.length) {
          const promises = busStopAudioFiles.map((o) => (getBusStopAudioSecureUrl(o.id)))
          const busStopAudioUrls = await Promise.all(promises)

          busStopAudioFiles.forEach((busStopAudioFile, index) => {
            busStopAudioFile.url = busStopAudioUrls[index]
            busStopAudioFile.localeLabel = this.locales.find((o) => (o.value === busStopAudioFile.locale))?.text || busStopAudioFile.locale
            busStopAudioFile.dateUpdatedLabel = `${formatTime(busStopAudioFile.dateUpdated)}, ${formatDate(busStopAudioFile.dateUpdated)}`
          })

          this.busStopAudioFiles = busStopAudioFiles
        } else {
          this.busStopAudioFiles = []
        }
      } catch (err) {
        console.error('loadBusStopAudioRecords error', err)
        addAlert({ message: err, type: 'error', isModal: true })
      } finally {
        this.busStopAudioApiInProgress = false
      }
    },
    async loadBusStopAudioForm (busStopAudio) {
      this.currentBusStopAudio = busStopAudio ? { ...busStopAudio } : { busStopId: this.currentBusStop.id }

      if (this.currentBusStopAudio.id) {
        this.busStopAudioDocument = {
          ...this.busStopAudioDocument,
          uploadedDocuments: [{
            filename: extractBaseNameFromFilePath(this.currentBusStopAudio.documentSrc),
            type: this.currentBusStopAudio.metadata.mimetype,
            size: this.currentBusStopAudio.metadata.size
          }]
        }
      } else {
        this.busStopAudioDocument = {
          ...this.busStopAudioDocument,
          uploadedDocuments: []
        }
      }
    },
    closeBusStopAudioForm () {
      this.currentBusStopAudio = null
    },
    clearDocumentUpload () {
      this.currentBusStopAudio.documentFile = null

      this.busStopAudioDocument = {
        ...this.busStopAudioDocument,
        documentsToUpload: []
      }
    },
    async saveBusStopAudio () {
      try {
        if (!this.currentBusStopAudio.locale) {
          alert('Please select a language/dialect')
          return
        }

        if (!this.currentBusStopAudio.documentFile) {
          alert('Please upload an audio file')
          return
        }

        this.busStopAudioApiInProgress = true

        if (this.currentBusStopAudio.id) {
          this.currentBusStopAudio = await updateBusStopAudio({
            id: this.currentBusStopAudio.id,
            locale: this.currentBusStopAudio.locale,
            documentFile: this.currentBusStopAudio.documentFile
          })
        } else {
          this.currentBusStopAudio = await createBusStopAudio({
            busStopId: this.currentBusStopAudio.busStopId,
            locale: this.currentBusStopAudio.locale,
            documentFile: this.currentBusStopAudio.documentFile
          })
        }

        this.clearDocumentUpload()
        this.loadBusStopAudioRecords(this.currentBusStop.id)
        this.closeBusStopAudioForm()
      } catch (error) {
        addAlert({ message: error, type: 'error', isModal: true })
      } finally {
        this.busStopAudioApiInProgress = false
      }
    },
    async saveBusStop () {
      try {
        this.apiInProgress = true
        let result = await updateBusStop({ id: this.currentBusStop.id, name: this.currentBusStop.name })
        // result = this.marshalBusStop(result)
        //
        // if (this.busStops && this.busStops.length) {
        //   const index = _.findIndex(this.busStops, o => (o.id === result.id))
        //   if (index > -1) {
        //     this.busStops[ index ] = result
        //   }
        // }

        this.longLatOnMap = null
        this.apiInProgress = false
        this.modalBusStopUpdate = false
        this.refreshMapBusStops = `refresh${Date.now()}`

        window.addEventListener('xBusStopsRefreshed', () => {
          this.longLatOnMap = result.longlat
        }, { once: true })

        addAlert({
          message: `Successfully updated bus stop: ${result.name}`,
          type: 'success',
          transient: true
        })
      } catch (err) {
        this.apiInProgress = false
        alert(`Error in saving bus stop: ${err && err.error ? err.error.message : JSON.stringify(err)}`)
      }
    },
    cancelBusStopUpdate () {
      if (this.isUpdateDirty()) {
        if (confirm('Any changes made will be lost, continue?')) {
          this.closeBusStopForm()
        }
      } else {
        this.closeBusStopForm()
      }
    },
    async deleteBusStopAudioForm (currentBusStopAudio) {
      if (!currentBusStopAudio.id) {
        alert('Please save at least once before deleting')
      }

      if (confirm('Are you sure you want to delete this audio file?')) {
        try {
          this.busStopAudioApiInProgress = true
          await deleteBusStopAudio(currentBusStopAudio.id)
          addAlert({ message: 'Audio file deleted', type: 'success' })
          this.loadBusStopAudioRecords(this.currentBusStop.id)
        } catch (err) {
          addAlert({ message: err, type: 'error' })
        } finally {
          this.busStopAudioApiInProgress = false
        }
      }
    }
  }
}
