import {
  formatDate,
  formatTime
} from '@smarttransit/common'

import {
  extractBaseNameFromFilePath
} from '@smarttransit/common-client'

import {
  addAlert
} from '../../../../../utilities/helpers'

import {
  deleteGenericBusStopAudio,
  getGenericBusStopAudio,
  getGenericBusStopAudioSecureUrl,
  setGenericBusStopAudio
} from '../../../../../services/bus-stops-service'

export default {
  name: 'bus-stops-generic-audio-modal',
  props: {
    openModal: Boolean,
    isEditable: Boolean,
    locales: {
      type: Array,
      required: true
    },
    onModalClose: Function
  },
  data () {
    return {
      genericBusStopAudioFiles: [],
      currentGenericBusStopAudio: null,
      modalGenericAudioUpdate: false,
      busStopAudioApiInProgress: false,
      genericBusStopAudioDocument: { documentsToUpload: [], uploadedDocuments: [] },
      genericTypes: [{ text: 'Next stop', value: 'next' }, { text: 'Arrived', value: 'arrived' }]
    }
  },
  mounted () {
    this.modalGenericAudioUpdate = this.$props.openModal
  },
  watch: {
    openModal (newVal) {
      this.modalGenericAudioUpdate = newVal

      if (newVal) {
        this.loadGenericAudioRecords()
      }
    },
    genericBusStopAudioDocument (documents) {
      if (this.currentGenericBusStopAudio) {
        this.currentGenericBusStopAudio.documentFile = null
      }

      if (documents?.documentsToUpload) {
        documents.documentsToUpload.forEach((newFile) => {
          if (!newFile.error) {
            this.currentGenericBusStopAudio = {
              ...this.currentGenericBusStopAudio,
              documentFile: newFile.file,
              documentFileUrl: URL.createObjectURL(newFile.file)
            }
          }
        })
      }
    }
  },
  computed: {
    filteredGenericTypes () {
      return this.genericBusStopAudioFiles && this.genericBusStopAudioFiles.length ? this.genericTypes.map((o) => {
        return { ...o, disabled: Boolean(this.genericBusStopAudioFiles.find((b) => (b.type === o.value && b.id !== this.currentGenericBusStopAudio.id))) }
      }) : this.genericTypes
    }
  },
  methods: {
    closeGenericBusStopModal () {
      this.modalGenericAudioUpdate = false

      if (this.$props.onModalClose) {
        this.$props.onModalClose()
      }
    },
    async loadGenericAudioRecords () {
      try {
        this.busStopAudioApiInProgress = true
        const genericBusStopAudioFiles = await getGenericBusStopAudio()

        if (genericBusStopAudioFiles && genericBusStopAudioFiles.length) {
          const promises = genericBusStopAudioFiles.map((o) => (getGenericBusStopAudioSecureUrl(o.id)))
          const busStopAudioUrls = await Promise.all(promises)

          genericBusStopAudioFiles.forEach((busStopAudioFile, index) => {
            busStopAudioFile.url = busStopAudioUrls[index]
            busStopAudioFile.typeLabel = this.genericTypes.find((o) => (o.value === busStopAudioFile.type))?.text || busStopAudioFile.type
            busStopAudioFile.localeLabel = this.locales.find((o) => (o.value === busStopAudioFile.locale))?.text || busStopAudioFile.locale
            busStopAudioFile.dateUpdatedLabel = `${formatTime(busStopAudioFile.dateUpdated)}, ${formatDate(busStopAudioFile.dateUpdated)}`
          })

          this.genericBusStopAudioFiles = genericBusStopAudioFiles
        } else {
          this.genericBusStopAudioFiles = []
        }
      } catch (err) {
        console.error('loadGenericAudioRecords error', err)
        addAlert({ message: err, type: 'error', isModal: true })
      } finally {
        this.busStopAudioApiInProgress = false
      }
    },
    async loadGenericBusStopAudioForm (busStopAudio) {
      this.currentGenericBusStopAudio = busStopAudio ? { ...busStopAudio } : {}

      if (this.currentGenericBusStopAudio.id) {
        this.genericBusStopAudioDocument = {
          ...this.genericBusStopAudioDocument,
          uploadedDocuments: [{
            filename: extractBaseNameFromFilePath(this.currentGenericBusStopAudio.documentSrc),
            type: this.currentGenericBusStopAudio.metadata.mimetype,
            size: this.currentGenericBusStopAudio.metadata.size
          }]
        }
      } else {
        this.genericBusStopAudioDocument = {
          ...this.genericBusStopAudioDocument,
          uploadedDocuments: []
        }
      }
    },
    async deleteGenericBusStopAudioForm (busStopAudio) {
      if (confirm(`Confirm deleting audio for ${busStopAudio.localeLabel}`)) {
        try {
          this.busStopAudioApiInProgress = true
          await deleteGenericBusStopAudio(busStopAudio.id)
          this.loadGenericAudioRecords()
        } catch (error) {
          console.error('deleteGenericBusStopAudioForm error', error)
          addAlert({ message: error, type: 'error', isModal: true })
        } finally {
          this.busStopAudioApiInProgress = false
        }
      }
    },
    closeGenericBusStopAudioForm () {
      this.currentGenericBusStopAudio = null
    },
    clearGenericDocumentUpload () {
      this.currentGenericBusStopAudio.documentFile = null

      this.genericBusStopAudioDocument = {
        ...this.genericBusStopAudioDocument,
        documentsToUpload: []
      }
    },
    async saveGenericBusStopAudio () {
      try {
        if (!this.currentGenericBusStopAudio.locale) {
          alert('Please select a language/dialect')
          return
        }

        if (!this.currentGenericBusStopAudio.id && !this.currentGenericBusStopAudio.documentFile) {
          alert('Please upload an audio file')
          return
        }

        if (!this.currentGenericBusStopAudio.type) {
          alert('Please select a type')
          return
        }

        this.busStopAudioApiInProgress = true

        this.currentGenericBusStopAudio = await setGenericBusStopAudio({
          settingId: this.currentGenericBusStopAudio.id,
          type: this.currentGenericBusStopAudio.type,
          locale: this.currentGenericBusStopAudio.locale,
          documentFile: this.currentGenericBusStopAudio.documentFile
        })

        this.clearGenericDocumentUpload()
        this.closeGenericBusStopAudioForm()
        this.loadGenericAudioRecords()
      } catch (error) {
        addAlert({ message: error, type: 'error', isModal: true })
      } finally {
        this.busStopAudioApiInProgress = false
      }
    }
  }
}
