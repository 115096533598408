<template>
  <v-dialog persistent v-model="modalBusStopUpdate" max-width="600px" v-if="currentBusStop">
    <v-card>
      <v-card-title class="text-xs-center">
        <v-text-field solo hide-details autofocus :readonly="!isEditable" @keyup.enter.native="saveBusStop()" label="Bus stop name" v-model="currentBusStop.name" append-icon="fas fa-pencil"></v-text-field>
      </v-card-title>
      <v-card-text class="px-2 pb-2">
        <v-layout row wrap v-if="!currentBusStopAudio">
          <v-flex md6 px-2 style="border-right: 1px solid black">
            <p>OTP Name: {{currentBusStop.externalName}}</p>
            <p>OTP ID: {{currentBusStop.externalId}}</p>
            <p>Longitude: {{currentBusStop.longlat[0]}}</p>
            <p>Latitude: {{currentBusStop.longlat[1]}}</p>
            <p>Date Created: {{currentBusStop.dateCreatedLabel}}</p>
            <p>Date Updated: {{currentBusStop.dateUpdatedLabel}}</p>
            <p>Date Last Synced: {{currentBusStop.dateSyncedLabel}}</p>
          </v-flex>
          <v-flex md6>
            <div class="mb-2 mx-2">
              <v-btn block small v-show="isEditable" @click="loadBusStopAudioForm()" :loading="busStopAudioApiInProgress" :disabled="busStopAudioApiInProgress"><v-icon>far fa-plus</v-icon>&nbsp; Add Audio</v-btn>
            </div>
            <div class="px-2" style="overflow-y: auto; max-height: 300px">
              <div v-for="busStopAudioFile in busStopAudioFiles" :key="busStopAudioFile.id">
                <hr class="my-3" style="border: 1px solid #ccc" />
                <div>
                  <audio v-if="busStopAudioFile.url" controls :src="busStopAudioFile.url">
                    Your browser does not support the <code>audio</code> element, <a :href="busStopAudioFile.url" target="_blank">download</a> instead
                  </audio>
                </div>

                <div class="mb-2 layout row justify-space-between align-center">
                  <div>
                    <small>{{busStopAudioFile.localeLabel}}</small>
                  </div>
                  <div class="ml-2">
                    <small>{{busStopAudioFile.dateUpdatedLabel}}</small>
                  </div>
                  <div class="ml-2" v-if="isEditable">
                    <v-btn icon small @click="deleteBusStopAudioForm(busStopAudioFile)">
                      <v-icon small color="error">fas fa-trash</v-icon>
                    </v-btn> &nbsp;
                    <v-btn fab small class="mx-0" @click="loadBusStopAudioForm(busStopAudioFile)">
                      <v-icon small>fas fa-pencil</v-icon>
                    </v-btn>
                  </div>
                </div>
              </div>
            </div>
          </v-flex>
        </v-layout>
        <div v-if="currentBusStopAudio">
          <p v-if="currentBusStopAudio.dateUpdatedLabel">Date updated: {{currentBusStopAudio.dateUpdatedLabel}}</p>

          <v-select
              outline
              mb-3
              :items="locales"
              label="Language/dialect"
              v-model="currentBusStopAudio.locale"
          />

          <st-uploader
              name="document"
              :mime-types="['audio/mpeg']"
              upload-label="Select MP3 Audio to upload"
              v-model="busStopAudioDocument"
          />

          <div class="layout justify-center">
            <audio v-if="currentBusStopAudio.documentFileUrl || currentBusStopAudio.url" class="mt-2" controls :src="(busStopAudioDocument.documentsToUpload.length && currentBusStopAudio.documentFileUrl) || currentBusStopAudio.url">
              Your browser does not support the <code>audio</code> element, <a :href="(busStopAudioDocument.documentsToUpload.length && currentBusStopAudio.documentFileUrl) || currentBusStopAudio.url" target="_blank">download</a> instead
            </audio>
          </div>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-layout row wrap>
          <v-flex sm6 py-2 pr-2>
            <v-btn block class="warning" v-if="!currentBusStopAudio" :disabled="apiInProgress" @click="cancelBusStopUpdate()">Cancel
            </v-btn>
            <v-btn block class="warning" v-if="currentBusStopAudio" :disabled="busStopAudioApiInProgress" @click="closeBusStopAudioForm()">Return to bus stop edit
            </v-btn>
          </v-flex>
          <v-flex sm6 py-2>
            <v-btn block v-if="!currentBusStopAudio" :disabled="!isUpdateDirty() || apiInProgress || !isEditable" :loading="apiInProgress" class="primary" @click="saveBusStop()">Save
            </v-btn>
            <v-btn block v-if="currentBusStopAudio" :disabled="busStopAudioApiInProgress" :loading="busStopAudioApiInProgress" @click="saveBusStopAudio()">Save Bus Stop Audio
            </v-btn>
          </v-flex>
        </v-layout>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import src from './src'
export default src
</script>
