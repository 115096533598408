<template>
    <div>
        <div id="busStopsHeader" ref="busStopsHeader">
            <br />
            <h1 class="text-xs-center">Bus Stops</h1>
            <div class="px-2 pb-2 layout justify-end wrap">
              <v-btn :disabled="!selectedRegion" @click.stop="loadBulkEdit()">
                  <v-icon left>fas fa-table</v-icon>
                  Bulk edit
              </v-btn>
              <download-button
                label="Export stops as CSV"
                download-type="text/csv"
                label-icon="fas fa-file-spreadsheet"
                :on-click="onGetBusStopsClick"
                :on-download="getBusStopsAsCsvData"
                :file-name="csvDataFilename"
                :disabled="!selectedRegion"
              />
              <v-btn @click.stop="modalGenericAudioUpdate = true">
                <v-icon small left>fas fa-volume</v-icon>
                Generic Bus Stop Audio
              </v-btn>
              <v-tooltip v-if="isSyncPossible" bottom>
                <template v-slot:activator="{ on }">
                  <v-btn flat icon><v-icon v-on="on">fal fa-info-circle</v-icon></v-btn>
                </template>
                <span>Update any new/deleted bus stops from the Open Trip Planner (OTP) server. Only one synchronize job can occur at a time</span>
              </v-tooltip>
              <div>
                <v-menu
                  v-model="syncMenu"
                  :close-on-content-click="true"
                  :nudge-width="200"
                  offset-y
                >
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on">
                      Sync Menu <v-icon right small>far fa-chevron-down</v-icon>
                    </v-btn>
                  </template>
                  <v-card class="pa-2">
                    <v-btn v-if="isSyncPossible" class="mb-3" block :disabled="!!(!selectedRegion || checkingCurrentJobStatus || currentJobStatus)" @click="updateFromOtp()">Start OTP Sync Job</v-btn>
                    <v-btn v-if="isSyncPossible" class="mb-3" block v-show="currentJobStatus !== 'error'" :disabled="!!(!selectedRegion || checkingCurrentJobStatus)" @click="stopUpdateFromOtp()">Stop OTP Sync Job If In Progress</v-btn>
                    <v-btn v-if="isSyncPossible" block flat class="warning" v-show="currentJobStatus === 'error'" @click="clearErrorFromOtpUpdate()">Clear error</v-btn>
                  </v-card>
                </v-menu>
              </div>
              <v-tooltip top v-if="isSyncPossible">
                  <template v-slot:activator="{ on }">
                      <v-btn flat icon>
                          <div v-if="!checkingCurrentJobStatus">
                              <v-icon color="success" v-on="on" v-if="!currentJobStatus">fal fa-check-circle</v-icon>
                              <v-icon color="warning" v-on="on" v-if="currentJobStatus === 'busy'">fas fa-sync fa-spin</v-icon>
                              <v-icon color="info" v-on="on" v-if="currentJobStatus === 'pending'">fal fa-hourglass-start</v-icon>
                              <v-icon color="danger" v-on="on" v-if="currentJobStatus === 'error'">fal fa-exclamation-triangle</v-icon>
                          </div>
                      </v-btn>
                  </template>
                  <span v-text="currentJobStatusText"></span>
              </v-tooltip>
            </div>
        </div>
        <v-layout row wrap v-if="!isBulkEdit">
            <v-flex sm8 xs6 py-2 class="layout justify-start">
                <div style="width: 100%">
                    <st-map
                      v-if="stMapApis"
                      disable-search
                      topbar
                      container-id="busStopsMap"
                      :mapbox-access-token="mapboxAccessToken"
                      :maptiler-key="maptilerKey"
                      :api="stMapApis"
                      :force-refresh-bus-stops="refreshMapBusStops"
                      :center-at-long-lat="longLatOnMap"
                      :height-offset="busStopsHeaderHeight"
                      :on-bus-stop-clicked="onMapBusStopClicked"
                      :on-region-change="onRegionChange" />
                </div>
            </v-flex>
            <v-flex sm4 xs6 py-2 class="layout justify-start" v-resize="onResize">
                <div style="width: 100%">
                    <v-card class="pa-2">
                      <div id="busStopsListHeader" ref="busStopsListHeader">
                          <v-text-field
                              v-model="searchKeywords"
                              append-icon="fal fa-search"
                              :rules="[v => !v || v.length >= 2 || 'Min 2 characters']"
                              :disabled="apiInProgress"
                              type="search"
                              placeholder="Search by name or OTP ID"
                              :loading="apiInProgress"
                              @keyup.enter.native="searchBusStops"
                              @click:append="searchBusStops"
                          />
                          <v-layout row wrap class="v-select--small">
                              <v-flex sm12 pl-1>
                                  <v-select
                                      hide-details
                                      height="40"
                                      dense
                                      outline
                                      :disabled="apiInProgress"
                                      :items="sortFilters"
                                      label="Order by"
                                      v-model="selectedSortFilter"
                                  />
                              </v-flex>
                          </v-layout>
                      </div>

                      <div :style="{height: busStopsListHeight, overflowY: 'auto'}">
                        <v-data-table
                          hide-headers
                          :items="busStops"
                          :pagination.sync="pagination"
                          :total-items="totalBusStops"
                          :loading="apiInProgress"
                          :rows-per-page-items="[5, 10, 25]"
                          @update:pagination="onPagination"
                          class="table--pagination--hide-rows-per-page"
                        >
                          <template v-slot:items="props">
                            <tr @click="onListBusStopClicked(props.item)" class="tr--pointer">
                              <td class="pa-2">
                                <v-layout row>
                                  <v-flex xs2>
                                    <v-btn small fab title="Show on map" @click.stop="showLongLatOnMap(props.item)">
                                      <v-icon>fas fa-map-marker-alt</v-icon>
                                    </v-btn>
                                  </v-flex>
                                  <v-flex xs10>
                                    <div><strong>{{ props.item.name }}</strong></div>
                                    <div class="small">
                                      <div>OTP Name: {{ props.item.externalName }}</div>
                                      <div>OTP ID: {{ props.item.externalId }}</div>
                                      <div>long: {{ props.item.longlat[0] }} lat: {{ props.item.longlat[1] }}, region:
                                        {{ props.item.routerId }}
                                      </div>
                                      <div>Date Updated: {{ props.item.dateUpdatedLabel }}</div>
                                      <div v-if="props.item.audioLanguageLabels && props.item.audioLanguageLabels.length">
                                        Audio:
                                        <v-chip small><small>{{ props.item.audioLanguageLabels.join(', ') }}</small>
                                        </v-chip>
                                      </div>
                                    </div>
                                  </v-flex>
                                </v-layout>
                              </td>
                            </tr>
                          </template>
                        </v-data-table>
                      </div>
                    </v-card>
                </div>
            </v-flex>
        </v-layout>
        <bus-stops-generic-audio-modal
          :open-modal="modalGenericAudioUpdate"
          :is-editable="isEditable"
          :locales="filteredLocales"
          :on-modal-close="() => modalGenericAudioUpdate = false"
        />
        <bus-stops-audio-modal
          :open-modal="modalBusStopUpdate"
          :selected-bus-stop="selectedBusStop"
          :is-editable="isEditable"
          :locales="filteredLocales"
          :on-modal-close="() => { modalBusStopUpdate = false; searchBusStops() }"
        />
        <v-dialog
          fullscreen
          hide-overlay
          transition="dialog-bottom-transition"
          scrollable
          v-model="isBulkEdit">
            <v-card>
                <v-toolbar card dark color="primary" dense height="48">
                    <img src="@/assets/logo-badge.png" style="height: 25px" alt="" />
                    <v-spacer />
                    <v-toolbar-title><v-icon left>fas fa-table</v-icon> Bus Stops Bulk Editor</v-toolbar-title>
                    <v-spacer />
                    <v-toolbar-items>
                        <v-btn dark flat @click.stop="exitBulkEdit" :loading="closingBulkEdit" :disabled="closingBulkEdit">
                            Close <v-icon right>far fa-times</v-icon>
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <div ref="bulkEditorHeader" class="table-row layout justify-space-around pa-2 bulk-editor-header">
                    <div>Date Updated</div>
                    <div>Last Synced with OTP</div>
                    <div>Long/Lat</div>
                    <div>OTP ID</div>
                    <div>OTP Name</div>
                    <div class="table-cell--input">Name</div>
                </div>
                <div style="height: 100%; overflow-y: auto">
                    <v-progress-circular style="position: absolute; top: 45%; left: 45%" color="primary" size="80" width="8" indeterminate v-if="closingBulkEdit" />
                    <RecycleScroller
                      v-if="enableVirtualList"
                      class="scroller"
                      :items="busStops"
                      :item-size="86"
                      key-field="id"
                      v-slot="{ item, index }"
                      page-mode
                      emit-update
                      @update="visibleBulkEditIndexes"
                    >
                        <div class="table-row table-row--divider layout justify-space-around align-center px-2">
                            <div>{{item.dateUpdatedLabel}}</div>
                            <div>{{item.dateSyncedLabel}}</div>
                            <div>{{item.longlat ? item.longlat.join(', ') : ''}}</div>
                            <div>{{item.externalId}}</div>
                            <div>{{item.externalName}}</div>
                            <div class="table-cell--input">
                                <v-text-field
                                  outline
                                  full-width
                                  :success="idState.currentBulkEditItem && idState.currentBulkEditItem.id === item.id && idState.currentBulkEditItem.apiState === 'success'"
                                  :error="idState.currentBulkEditItem && idState.currentBulkEditItem.id === item.id && idState.currentBulkEditItem.apiState === 'error'"
                                  height="50"
                                  class="text-field--compact"
                                  :autofocus="index === 0"
                                  @blur="busStopNamePossiblyEdited(item)"
                                  v-model="item.name"
                                  :loading="idState.currentBulkEditItem && idState.currentBulkEditItem.id === item.id && idState.currentBulkEditItem.apiState === 'loading'"
                                  :append-icon="getBusStopIconState(idState.currentBulkEditItem && idState.currentBulkEditItem.id === item.id ? idState.currentBulkEditItem : null)"
                                  :rules="[v => !!(v && v.length) || 'Stop name required']"
                                  placeholder="Enter stop name"
                                />
                            </div>
                        </div>
                    </RecycleScroller>
                </div>
                <div ref="bulkEditorFooter" class="pa-2 layout justify-end bulk-editor-footer">
                    <div><small>Total: {{totalBusStops}}</small></div>
                </div>
                <v-dialog v-model="modalSiteAlert" max-width="500px">
                    <v-card>
                        <v-card-title>
                            {{modalSiteAlertData ? modalSiteAlertData.type || 'Error' : 'Error'}}
                        </v-card-title>
                        <v-card-text style="overflow-y: scroll; max-height: 300px;" class="px-2 pb-2">
                            <v-alert :value="true" :type="modalSiteAlertData && modalSiteAlertData.type">
                                {{modalSiteAlertData ? modalSiteAlertData.error ? modalSiteAlertData.error.message : (modalSiteAlertData.message) : 'Unknown error'}}
                            </v-alert>
                        </v-card-text>
                        <v-card-actions>
                            <v-btn color="primary" flat @click="modalSiteAlert = false">Close</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-card>
        </v-dialog>
    </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<!--<style scoped>-->
<!--    //.table-row&#45;&#45;divider {-->
<!--    //    border-bottom: 1px solid #f5f5f5;-->
<!--    //}-->
<!--    //.table-row&#45;&#45;divider:hover {-->
<!--    //    background-color: #f5f5f5;-->
<!--    //}-->
<!--    //.table-row > div {-->
<!--    //    width: 14%;-->
<!--    //    min-width: 100px;-->
<!--    //}-->
<!--    //.table-row .table-cell&#45;&#45;input {-->
<!--    //    width: 28%;-->
<!--    //    min-width: 150px;-->
<!--    //}-->
<!--    //.bulk-editor-footer,-->
<!--    //.bulk-editor-header {-->
<!--    //    background-color: #f5f5f5;-->
<!--    //}-->
<!--    //.text-field&#45;&#45;compact >>> .v-text-field__slot,-->
<!--    //.text-field&#45;&#45;compact >>> .v-input__append-inner {-->
<!--    //    transform: scale(0.8) !important;-->
<!--    //    transform-origin: left !important;-->
<!--    //}-->
<!--</style>-->

<script>
import './components/generic-audio-modal'
import './components/audio-modal'
import src from './src'
export default src
</script>
