<template>
  <v-dialog persistent v-model="modalGenericAudioUpdate" max-width="500px" v-if="genericBusStopAudioFiles">
    <v-card>
      <v-card-title class="text-xs-center">
        <h2 style="width: 100%">Generic Bus Stop Audio Files</h2>
      </v-card-title>
      <v-card-text class="px-2 py-0">
        <div v-if="!currentGenericBusStopAudio">
          <div class="mx-2 text-xs-right">
            <v-btn small v-show="isEditable" @click="loadGenericBusStopAudioForm()" :loading="busStopAudioApiInProgress" :disabled="busStopAudioApiInProgress"><v-icon left>far fa-plus</v-icon> Add Generic Audio</v-btn>
          </div>
          <div class="px-2" style="overflow-y: auto; max-height: 300px">
            <div v-for="genericBusStopAudioFile in genericBusStopAudioFiles" :key="genericBusStopAudioFile.id">
              <hr class="my-3" style="border: 1px solid #ccc" />
              <div>
                <audio v-if="genericBusStopAudioFile.url" controls :src="genericBusStopAudioFile.url">
                  Your browser does not support the <code>audio</code> element, <a :href="genericBusStopAudioFile.url" target="_blank">download</a> instead
                </audio>
              </div>

              <div class="mb-2 layout row justify-space-between align-center">
                <div>
                  <small>{{genericBusStopAudioFile.typeLabel}}</small>
                </div>
                <div>
                  <small>{{genericBusStopAudioFile.localeLabel}}</small>
                </div>
                <div class="ml-2">
                  <small>{{genericBusStopAudioFile.dateUpdatedLabel}}</small>
                </div>
                <div class="ml-2" v-if="isEditable">
                  <v-btn icon small @click="deleteGenericBusStopAudioForm(genericBusStopAudioFile)">
                    <v-icon small color="error">fas fa-trash</v-icon>
                  </v-btn> &nbsp;
                  <v-btn fab small class="mx-0" @click="loadGenericBusStopAudioForm(genericBusStopAudioFile)">
                    <v-icon small>fas fa-pencil</v-icon>
                  </v-btn>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="currentGenericBusStopAudio">
          <p v-if="currentGenericBusStopAudio.dateUpdatedLabel">Date updated: {{currentGenericBusStopAudio.dateUpdatedLabel}}</p>
          <v-container grid-list-sm="3" class="pa-0">
            <v-layout row wrap>
              <v-flex sm6>
                <v-select
                    outline
                    mb-3
                    item-disabled="disabled"
                    :items="filteredGenericTypes"
                    label="Generic type"
                    v-model="currentGenericBusStopAudio.type"
                />
              </v-flex>
              <v-flex sm6>
                <v-select
                  outline
                  mb-3
                  :items="locales"
                  label="Language/dialect"
                  v-model="currentGenericBusStopAudio.locale"
                />
              </v-flex>
            </v-layout>
          </v-container>

          <st-uploader
              name="document"
              :mime-types="['audio/mpeg']"
              upload-label="Select MP3 Audio to upload"
              v-model="genericBusStopAudioDocument"
          />

          <div class="layout justify-center">
            <audio v-if="currentGenericBusStopAudio.documentFileUrl || currentGenericBusStopAudio.url" class="mt-2" controls :src="(genericBusStopAudioDocument.documentsToUpload.length && currentGenericBusStopAudio.documentFileUrl) || currentGenericBusStopAudio.url">
              Your browser does not support the <code>audio</code> element, <a :href="(genericBusStopAudioDocument.documentsToUpload.length && currentGenericBusStopAudio.documentFileUrl) || currentGenericBusStopAudio.url" target="_blank">download</a> instead
            </audio>
          </div>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-layout row wrap>
          <v-flex sm6 py-2 pr-2>
            <v-btn block class="warning" v-if="!currentGenericBusStopAudio" :disabled="busStopAudioApiInProgress" @click="closeGenericBusStopModal()">Close
            </v-btn>
            <v-btn block class="warning" v-if="currentGenericBusStopAudio" :disabled="busStopAudioApiInProgress" @click="closeGenericBusStopAudioForm()">Return to list
            </v-btn>
          </v-flex>
          <v-flex sm6 py-2>
            <v-btn block :disabled="!currentGenericBusStopAudio || busStopAudioApiInProgress" :loading="busStopAudioApiInProgress" @click="saveGenericBusStopAudio()">Save Generic Audio
            </v-btn>
          </v-flex>
        </v-layout>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import src from './src'
export default src
</script>
